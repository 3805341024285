import {
  Seo,
  Html,
} from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import {
  TypographyBlock,
  PageGrid,
  PageGridItem,
  PageSection,
  Button,
  InlineList,
  Typography,
} from "@wsui/base";
import React from "react";

import useError404Settings from "../../../hooks/error404";
import Image from "../wsui/components/Image.jsx";

const renderItem = ({
  item: { children, as: Component = "div" },
  ...props
}) => (
  <Component
    {...props}
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    {children}
  </Component>
);

export default function NotFoundPage() {
  const { errordescription, errortitle, field404ErrorImage } =
    useError404Settings();
  return (
    <article>
      <Seo title="Vi kan inte hitta den här sidan" />
      <PageSection>
        <PageGrid renderItem={renderItem}>
          {field404ErrorImage && (
            <PageGridItem>
              <Image {...field404ErrorImage} />
            </PageGridItem>
          )}
          <PageGridItem>
            <TypographyBlock marginAfter>
              {errortitle && <Typography variant="h2">{errortitle}</Typography>}
              {errordescription && <Html>{errordescription}</Html>}
            </TypographyBlock>
            <InlineList>
              <Button variant="primary" url="/">
                Tillbaka till startsidan
              </Button>
            </InlineList>
          </PageGridItem>
        </PageGrid>
      </PageSection>
    </article>
  );
}
