import { useStaticQuery, graphql } from "gatsby";

export default function useError404Settings() {
  return useStaticQuery(graphql`
    query Error404Settings {
      wp {
        acfOptions404 {
          error404 {
            errordescription
            errortitle
            field404ErrorImage {
              ...WP_ImageLarge
            }
          }
        }
      }
    }
  `).wp.acfOptions404.error404;
}
